// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
  },
};

class Blog_2017_11_11_Williams_First_Rappel extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          William is always asking if he can go canyoneering with me. We finally
          made it happen.
        </p>
        <Image caption="William geared up to go rappelling" image={data._1} />
        <p>
          We went to Dry Canyon since it has a good steep approach and many
          different cliff elevations. We went on a couple of different cliffs
          and each time at the beginning, he told me he was scared. Upon
          touching the ground, though, he would ask to go again and would really
          enjoy it the second time.
        </p>
        <Image
          caption="William and I on our first rappel together"
          image={data._2}
        />
        <p>
          We rappelled <GlossaryLink id="tandem-rappel">tandem</GlossaryLink>{' '}
          with me controlling the rappel device.
        </p>
        <p>
          I started the day with him attached via a{' '}
          <GlossaryLink>Purcell Prusik</GlossaryLink> so I could get the length
          right.
        </p>
        <p>
          By the end, I had settled on a{' '}
          <GlossaryLink id="sling">doubled-up 36" Dyneema sling</GlossaryLink>.
        </p>
        <p>
          I extended the rappel device using my{' '}
          <GlossaryLink>safety tether</GlossaryLink>, also a Purcell.
        </p>
        <p>
          Finally, I used a <GlossaryLink>Valdotain Tresse</GlossaryLink> as a
          below-the-device <GlossaryLink>hands-free backup</GlossaryLink> since
          I knew I would have to focus a lot of effort guiding my son down.
        </p>
        <p>It was a very fun morning and I can't wait to go with him again.</p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2017-11-11-williams-first-rappel')(
  Blog_2017_11_11_Williams_First_Rappel
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2017-11-11-williams-first-rappel/2017_11_11_williams_first_rappel-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2017-11-11-williams-first-rappel/2017_11_11_williams_first_rappel-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
